import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/the-benefits-of-being-an-eternal-learner",
  "title": "The Benefits of Being an Eternal Learner",
  "excerpt": "Constantly asking questions and always wondering why",
  "tags": ["web development", "templating", "gulp", "email"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/daria-nepriakhina-i5iIhHSAtp4-unsplash_jm12u3.jpg",
    "originalHeight": 2688,
    "originalWidth": 4032,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2018-02-07T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This may not describe everyone, but if it describes you then you know how it feels to keep an eye out for new technology and waiting to see if it becomes useful. Hearing people talk about it and wondering when it’s appropriate to use the newest javascript framework. Also knowing when it is being accepted and widely used in the field before devoting yourself to learning it.`}</p>
    <p>{`This ability, to constantly want to learn and never being tired, is very useful for the tech field which is always changing. Your never stuck with an exhaustive feeling of “I have to learn something new”. If it’s necessary and makes sense for the moment and future your all for it. You already know there are so many talented people that there will be different and hopefully better things ahead. You look forward to learning them.`}</p>
    <p>{`Learning a new skill or program is more a fact-finding mission than an arduous task. sometimes with a side benefit of being a lot of fun. You can try and answer all the questions that go with learning something new. What makes it different than the program that preceded it? Why is this one better? What could make it better than what it is right now?`}</p>
    <p>{`It’s just a constant stream of questions trying to figure out what makes this so great and wondering if it will ultimately work out better. With the knowledge that sometimes people jump on a bandwagon only to learn it’s not needed or not done well. A good tip to learning is when to start devoting yourself to something. Fads come and go and you need to learn where to spend your energy and time.`}</p>
    <p>{`Being curious opens up one of the greatest avenues to problem solving, whether in development or design. It’s asking questions. Being curious enough to think of a situation and only wanting to clarify the intent with questions. How will this be used by the users? How does this platform scale? What happens if this framework can’t be supported any longer? What future functionality are we looking to work toward? What current functionality is different and/or better than the current technology?`}</p>
    <p>{`Eternal learning is one of the most fun parts of my week, really my life. I never feel afraid to ask questions and try to satisfy my thoughts of why. Or cease being curious and learning about new things that can be used for the future. It’s what helps us develop and devise ways to effectively help more people and put the new technologies we create to greatest use. It also turns those outlandish ideas into reality and those realities into amazing things.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      